// index.js 调用接口的方法
import { getD, postD } from './api'

class Home {
    //团队
    static getTeam(parme = {}) {
        return getD('personal/getTeam', parme, true)
    }
    //  首页-（公告/banner)
    static home(parme = {}) {
        return getD('home', parme)
    }
    //  首页-理财信息
    static getManage(parme = {}) {
        return getD('personal/getManage', parme)
    }
    // 投资规格
    static getInvest(parme = {}) {
        return getD('personal/getInvest', parme)
    }
    // 投资记录
    static getInvestLog(parme = {}) {
        return getD('invest/index', parme)
    }
    // 提币
    static withdrawal(parme = {}) {
        return postD('withdrawal/store', parme)
    }
    // 用户
    static getUser() {
        return getD('personal/getUser')
    }
    // 提币记录
    static withdrawalList(parme = {}) {
        return getD('withdrawal/index', parme)
    }

    // 公告列表
    static noticeList(parme = {}) {
        return getD('notice/index', parme)
    }

    // 公告详情
    static noticeDetail(parme = {}) {
        return getD('notice/show', parme)
    }

    // 资产日志
    static assetlog(parme = {}) {
        return getD('log/index', parme)
    }
    //币种
    static currency() {
        return getD('/getConst')
    }
    //签到日历
    static sign() {
        return getD('/sign/index')
    }
    //签到
    static store() {
        return postD('/sign/store')
    }
    //投资详情
    static show(params={}) {
        return getD('invest/show',params)
    }
    //复投
    static repeat() {
        return postD('invest/repeat')
    }
      //收益提取    
      static extract(params={}) {
        return postD('invest/extract',params)
    }
      //期数比例   
      static getTime() {
        return getD('invest/getTime')
    }
    //


}

export default Home;