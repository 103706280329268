
module.exports = {
    language: {
        zh: '中文',
        en: '英文',
        tw: '中文繁体',
        wallet: '连接钱包',
        rate: '币种汇率',

        total: '总收益',
        balance: '可用余额',
        rewards: '理财奖励',
        incentive: '推广奖励',
        Community: '社区奖励',

        Withdraw: '提币',
        提取:'提取',
        提取成功:'提取成功',
        Manage: '理财',
        invest: '投资',
        invest1: '投资进行中',
        team: '团队',
        link: '点击跳链接',

        copy: '点击复制地址',
        balances: '余额',
        multiple: '选择倍数',
        multiple1: '第{multiple}期',
        periods: '当前期数 ',
        times: '倍',
        period:'期',
        期数:'期数',
        amount: '输入金额',
        the: '请输入金额',
        summarize: '概述',
        settlement: '结算方式',
        daily: '每日结算',
        ratio: '总收益比例',
        earnings: '预估每日收益',
        revenue: '预估收益总额',
        firstearningsreleasetime: '首次收益发放时间',
        accredit: '授权',
        parent: '绑定上级',
        parentaddress: '请输入上级地址',
        parenaddress: '上级地址',
        node: '小区有效节点',
        hierarchy: '收益层级',
        小区业绩:'小区业绩',

        mydirect: '我的直推',
        time: '时间',
        nodata: '暂无数据',

        announcement: '公告',
        info: '详情',

        assetslog: '资产日志',
        invitefriends: '邀请好友',
        investmentrecord: '投资记录',
        inprogress: '进行中',
        finished: '已完成',
        investmentamount: '投资金额',
        togainrevenue: '已获取收益',
        estimatedresidualearnings: '预计剩余收益',
        Confirm: '确认',
        Cancel: '取消',
        withdrawalrecord: '提币记录',
        pending: '待处理',
        success: '成功',
        failed: '失败',
        availablequantity: '可用数量',
        commissionrate: '手续费',
        withdrawalamount: '提币数量',
        pleaseentertheamountofwithdrawal: '请输入提币数量',
        withdrawaladdress: '提币地址',
        extract: '提取',


        replication: '地址复制成功',
        than: '填写数量要大于',
        withdrawal: '提币成功',
        预估周期: '预估周期(天)',
        周期:'周期',

        be: '授权数量必须为',
        be1: '投资数量必须为',
        among: '之间',
        transaction: '交易已发起 等待确认中',
        complete: '交易完成',
        cancela: '取消交易',
        insufficient: '余额不足',
        exceeded: '授权金额已超出',
        enter: '请输入有效地址',
        valid: '请输入有效金额',
        currency: '提币',
        more: '没有更多了',
        none: '暂无',
        plswallet: '请连接钱包',
        请求超时: '请求超时',
        网络错误: '网络错误',

        等级: "等级",
        所需小区数量: "小区业绩",
        个: "个",
        收益比例: "收益比例",
        客服: "客服",
        查看更多: "查看更多",
        发送: "发送",
        断开连接: "断开连接",
        连接失败: "連接失敗",
        签到: "签到",
        签到日志: "签到日志",
        已连续签到: "已连续签到",
        天: "天",
        点击签到: "点击签到",
        签到规则: "签到规则",
        签到成功: "签到成功",

        每日签到送: `每日签到奖励：用户每天签到可获得{tokensName}奖励。`,
        每次可随机获得: "随机奖励金额：每次签到可随机获得",
        签到时必须有投资中的订单: "签到条件：用户必须有正在进行的投资订单才能签到。",
        连续签到可获得更多的: `连续签到奖励：连续签到可获得更多的{tokensName}。`,
        中断后可继续签到: "中断后可继续：即使签到中断，用户仍可继续签到。",


        状态: '状态',
        认购时间: '认购时间',
        每日收益: '每日收益(预估)',
        累计收益: '累计收益',
        到期时间: '预计到期',
        复投: '复投',
        投资中: '投资中',
        已完成: '已结束',
        投资金额: '投资金额',
        投资详情: '投资详情',
        收益明细: '收益明细',
        复投成功: '复投成功',
        取消复投成功: '取消复投成功',
        未提取收益: '未提取收益',
    },

}