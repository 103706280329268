module.exports = {
    language: {
        zh: 'Chinese',
        en: 'English',
        tw: 'Traditional Chinese',
        wallet: 'Connected',
        rate: 'Exchange rate',
        total: 'Total revenue',
        balance: 'Available balance',
        rewards: 'Financial rewards',
        incentive: 'Promotion incentive',
        Community: 'Community reward',
        Withdraw: 'Withdraw currency',
        提取:'Extract',
        提取成功:'The extraction was successful',
        Manage: 'Manage money',
        invest: 'Invest',
        invest1: 'The investment is ongoing',
        team: 'Team',
        link: 'Click jump ',
        copy: 'Click copy ',
        balances: 'Balance',
        multiple: 'Selection multiple',
        multiple1: 'No.{multiple}',
        periods: 'Current Issue',
        times: 'x',
        period:'period',
        amount: 'Input amount',
        the: 'Please enter the amount',
        summarize: 'Summarize',
        settlement: 'Settlement method',
        daily: 'Daily settlement',
        ratio: 'Percentage of total proceeds',
        earnings: 'Estimated daily earnings',
        revenue: 'Estimated total revenue',
        firstearningsreleasetime: 'First earnings release time',
        accredit: 'Accredit',
        parent: 'Bind parent',
        parentaddress: 'Pls enter the parent addr',
        parenaddress: 'Parent address',
        node: 'Cell effective node',
        hierarchy: 'Income hierarchy',
        团队业绩:'Team performance',
        小区业绩:'Community Performance',
        mydirect: 'My straight push',
        time: 'Time',
        nodata: 'No data available',

        announcement: 'Notice',
        info: 'Details',

        assetslog: 'Asset log',
        invitefriends: 'Invite Friends',
        investmentrecord: 'Investment record',
        inprogress: 'Proceed',
        finished: 'Finished',
        investmentamount: 'Investment',
        togainrevenue: 'Gain revenue',
        estimatedresidualearnings: 'Residual income',
        Confirm: 'Verify',
        Cancel: 'Cancel',
        withdrawalrecord: 'Withdrawal record',
        pending: 'Pending',
        success: 'Success',
        failed: 'Lose',
        availablequantity: 'Available quantity',
        commissionrate: 'Handling charge',
        withdrawalamount: 'Withdrawal amount',
        pleaseentertheamountofwithdrawal: 'Please enter the withdrawal amount',
        withdrawaladdress: 'Withdrawal address',
        extract: 'Withdraw',

        replication: 'Address replication succeeded',
        than: 'Fill in more than',
        withdrawal: 'Successful withdrawal',
        预估周期: 'Estimated period(Day)',
        周期:'Cycle',
        be: 'The authorized quantity must be',
        be1: 'The amount of investment must be',
        among: 'Among',
        transaction: 'Transaction initiated pending confirmation',
        complete: 'Transaction completed',
        cancela: 'Cancel a transaction',
        insufficient: 'Insufficient balance',
        exceeded: 'The authorized amount has been exceeded',
        enter: 'Please enter a valid address',
        valid: 'Please enter a valid amount',
        currency: 'Withdraw currency',
        more: 'No more',
        none: 'None',
        plswallet: 'Please connect wallet',

        请求超时: 'Request timeout',
        网络错误: 'Network error',
        期数:'Instalments',
        等级: "Level",
        所需小区数量: "Community performance",
        个: "a",
        收益比例: "Income ratio",
        客服: "Service",
        查看更多: "View More",
        发送: "Send",
        断开连接: "Disconnect",
        连接失败: "Connect failed",
        签到: "Sign in",
        签到日志: "Sign in logs",
        已连续签到: "Checked in",
        天: " Day",
        点击签到: " Click to sign in",
        签到规则: "Check-in Rules",
        签到成功: "Successful check-in",
        每日签到送: `Daily Check-In Reward: Users receive {tokensName} rewards for checking in daily.`,
        每次可随机获得: "Random Reward Amount: Each check-in offers a random reward ranging from",
        签到时必须有投资中的订单: "Check-In Requirement: Users must have an active investment order to be eligible for check-in.",
        连续签到可获得更多的: `Rewards for Consecutive Check-Ins: Users earn more {tokensName} for checking in consecutively.`,
        中断后可继续签到: "Continuation After Interruption: Users can continue to check in even if their streak is interrupted.",
    
        状态: 'Status',
        认购时间: 'Subscription Time',
        每日收益: 'Daily Returns (Estimate)',
        累计收益: 'Cumulative Return',
        到期时间: 'Expected expiration',
        复投: 'Reinvestment',
        投资中: 'Investing',
        已完成: 'Ended',
        投资金额: 'Investment Amount',
        投资详情: 'Investment Details',
        收益明细: 'Earnings Breakdown',
        复投成功: 'Reinvestment successful',
        取消复投成功: 'Cancel Reinvestment Successful',

        未提取收益: 'Unwithdrawn earnings',
    },
}