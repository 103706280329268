<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
import store from '@/store'
import { Locale } from 'vant';
import en from 'vant/es/locale/lang/en-US';
import zh from 'vant/es/locale/lang/zh-CN';
import tw from 'vant/es/locale/lang/zh-TW';
// import { accountsChanged } from '@/utils/index'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },

  data() {
    return {
      isRouterAlive: true
    }
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  },
  mounted() {
    let that = this
    if (this.$store.state.lang == 'en') {
      Locale.use('en-US', en)
    } else if (this.$store.state.lang == 'zh-CN') {
      Locale.use('zh-CN', zh)
    } else if (this.$store.state.lang == 'zh-TW') {
      Locale.use('zh-TW', tw)
    } else {
      this.$i18n.locale = 'en'
      this.$store.state.lang = this.$i18n.locale
      Locale.use('en-US', en)
      sessionStorage.setItem('lang', 'en')
    }
    window.ethereum.on("accountsChanged", function (accounts) {
      if (accounts[0]) {
        store.state.address = accounts[0]
        sessionStorage.setItem('address', accounts[0])
        that.$router.push('/')
        that.isRouterAlive = false
        setTimeout(() => {
          that.isRouterAlive = true
        }, 100)
      } else {
        store.state.address = ''
        sessionStorage.setItem('address', '')
        that.isRouterAlive = false
        that.$router.push('/')
        setTimeout(() => {
          that.isRouterAlive = true

        }, 100)

      }
    });

  }
}
</script>
<style>
page {
  background-color: #1C1C1C;
}

.van-empty__image {
  opacity: 0.3;
}
</style>

<style>
#app {
  width: 100%;
  height: 100%;
  background-color: #1C1C1C;
}
</style>
